import { Tabs, TabsProps } from 'antd';
import { FC, ReactNode } from 'react';

interface IDgTab extends TabsProps {
	activeTab: string | undefined;
	tabs: {
		key: string;
		label: ReactNode;
		children: ReactNode;
	}[];
	header?: ReactNode;
}

const DgTab: FC<IDgTab> = ({ tabs, activeTab, onChange, header,...props }) => {
	//functions
	const handleTabChange = (key: string) => {
		if (onChange) onChange(key);
	};

	tabs = tabs.map((tab) => ({
		...tab,
		children: (
			<>
				{header ? header : null}
				{tab.children}
			</>
		),
	}));

	return <Tabs defaultActiveKey={activeTab} activeKey={activeTab} onChange={handleTabChange} items={tabs} {...props} />;
};

export default DgTab;

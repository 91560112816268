import { AxiosResponse } from 'axios';

export const convertPersianNumberToEnglish = (s = '', number?: boolean) => {
	const elem = s
		.toString()
		.replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d) + '')
		.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d) + '');
	if (number) return +elem;
	return elem;
};

// date picker
export const convertDatePicker = (dates: any) => {
	if (dates === null) return undefined;
	return typeof dates === 'object' ? convertPersianNumberToEnglish(dates?.format('YYYY/MM/DD')) : dates;
};

export const payload = (res: AxiosResponse) => {
	return JSON.parse(res.config.data);
};

// separator

export const separator = (input: string | number, seperater = ',', seperateLength = 3) => {
	if (input === null || input === undefined) return '';
	input = input.toString();
	let result = '';
	let count = 0;
	for (let i = input.length - 1; i > -1; i--) {
		if (count === seperateLength) {
			result = seperater + result;
			count = 0;
		}
		result = input.charAt(i) + result;
		count++;
	}
	return result;
};

// paginate data
export const paginateDataHandler = (data: any[], page = 1, limit = 15) => data.slice(page === 1 ? 0 : (page - 1) * limit, limit === 1 ? 15 : page * limit);

// selected link by route
export const isSelectedItemRoute = (to: string, currentRoute: string, exact = true) => {
	return exact ? to === currentRoute : currentRoute.includes(to);
};

// remove element array
export const removeArrayElement = (array: any[], element: any) => {
	const index = array.indexOf(element);
	if (index > -1) {
		array.splice(index, 1);
	}

	return array;
};

export const stringToBoolean = (string: string | undefined) => {
	string = string + '';
	switch (string?.toLowerCase()?.trim()) {
		case 'true':
		case 'yes':
		case '1':
			return true;
		case 'false':
		case 'no':
		case '0':
		case null:
			return false;
		default:
			return undefined;
	}
};

export const convertTimeStampToDate = (timeStamp: number | string) => {
	const date = new Date(+timeStamp * 1000);

	let formatted_string = date.getFullYear() + '/';

	if (date.getMonth() < 9) {
		formatted_string += '0';
	}
	formatted_string += date.getMonth() + 1;
	formatted_string += '/';

	if (date.getDate() < 10) {
		formatted_string += '0';
	}

	formatted_string += date.getDate();

	return { date: formatted_string, time: date.toLocaleTimeString(), dateTime: `${formatted_string} - ${date.toLocaleTimeString()}` };
};

export const hiddenText = (value: string) => {
	return { hiddenValue: value.replace(/^(....)(.*)(....)$/, (a, b, c, d) => b + c.replace(/.{4}/g, '*') + d), value };
};

export const extractNumberFromString = (s: string) => {
	const elem = s
		.toString()
		.replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d) + '')
		.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d) + '')
		.replace(/[^0-9.]+/g, '');
	return elem;
};

export const convertTimeStampToCountDown = (
	endTimeStamp: number,
	startTimeStamp?: number,
	callBack?: (val: any, percentage?: number) => void,
	type: 'daysResult' | 'detailResult' = 'daysResult'
) => {
	if (!endTimeStamp) return callBack?.('EXPIRED', 0);
	const countDownDate = new Date(endTimeStamp * 1000).getTime();

	const interval = setInterval(function () {
		const now = new Date().getTime();

		const distance = countDownDate - now;

		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);

		if (distance < 0) {
			clearInterval(interval);
			return callBack?.('EXPIRED', 0);
		}

		const range = endTimeStamp * 1000 - (startTimeStamp || 0) * 1000;
		const diff = Math.max(0, endTimeStamp * 1000 - new Date().getTime());
		const percentage = (100 * diff) / range;

		// console.log('distance', distance);

		const daysResult = days ? days + ' days' : hours || minutes ? ' 1 day' : '';
		const detailResult = (days ? days + 'd ' : '') + (hours || days ? hours + 'h ' : '') + (minutes || hours ? minutes + 'm ' : '') + seconds + 's ';

		type === 'detailResult' && callBack?.(detailResult, percentage);
		type === 'daysResult' && callBack?.(daysResult, percentage);
	}, 1000);
};

export const convertSvgToUrl = (svg: string) => {
	const blob = new Blob([svg || ''], { type: 'image/svg+xml' });
	return URL.createObjectURL(blob);
};

const toPlainString = (num: string | number) => {
	return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (a, b, c, d, e) {
		return e < 0 ? b + '0.' + Array(1 - e - c.length).join(0 + '') + c + d : b + c + d + Array(e - d.length + 1).join(0 + '');
	});
};

export const mainFormatNumber = (num: string | number, { point = 0, type = '' } = {}) => {
	if (!num) {
		return 0;
	}
	const number = toPlainString(num);
	const numParts = number.split('.');

	const firstPart = numParts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	if (type === 'irt') return firstPart;

	let secondPart = null;
	if (numParts.length > 1) {
		secondPart = numParts[1].toString().substring(0, point ? point : type === 'usdt' ? 2 : 6);
	}

	let formattedNumber = firstPart;
	if (secondPart) formattedNumber = `${firstPart}.${secondPart}`;
	if (!secondPart && point) formattedNumber += '.00';
	if (point && secondPart) {
		for (let i = 0; i < point - secondPart.length; i++) {
			formattedNumber += '0';
		}
	}

	if (!point && secondPart) {
		for (let i = formattedNumber.length - 1; i > 0; i--) {
			if (formattedNumber[i] === '0') {
				formattedNumber = formattedNumber.substring(0, i);
			} else if (formattedNumber[i] === '.') {
				formattedNumber = formattedNumber.substring(0, i);
				break;
			} else {
				break;
			}
		}
	}

	return formattedNumber;
};

export const numberFormat = (e = '') => {
	let value = convertPersianNumberToEnglish(e) + '';
	value = value.replace(/[^\d.,]/g, '');

	let newValue = '';
	let hasDot = false;
	for (let i = 0; i < value.length; i++) {
		if (hasDot && value[i] === '.') continue;
		if (value[i] === '.') {
			hasDot = true;
		}
		newValue += value[i];
	}
	value = newValue;

	const numParts = value.split('.');
	numParts[0] = numParts[0].replaceAll(',', '');
	numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	return numParts.join('.');
};

// sum object values
export const sumObjectValues = (obj: any) => {
	return Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);
};

// remove dash
export const removeDashFromString = (value: string) => value.replace(/-/g, ' ');

export const breadcrumbText = (value: string) => removeDashFromString(value)?.charAt(0).toUpperCase() + removeDashFromString(value).slice(1);

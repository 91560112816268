import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { FC } from 'react';
const { TextArea } = Input;

const DgTextArea: FC<TextAreaProps> = ({ ...props }) => {
	return <TextArea rows={3} {...props} />;
};

export default DgTextArea;

import { ScaleLoader } from 'react-spinners';
import { FC, memo, useEffect } from 'react';

const GlobalLoading: FC = () => {
	// useEffect(() => {
	// 	(document.getElementById('lottie') as HTMLElement).style.display = 'block';

	// 	const script = document.createElement('script');
	// 	script.src = '/panel/animateLogo.js';
	// 	script.async = true;
	// 	script.id = 'loader';
	// 	document.body.appendChild(script);

	// 	return () => {
	// 		document.body.removeChild(script);
	// 		(document.getElementById('lottie') as HTMLElement).innerHTML = '';
	// 		(document.getElementById('lottie') as HTMLElement).style.display = 'none';
	// 	};
	// }, []);

	return (
		<>
			{/* <div className='w-full h-screen fixed flex justify-center items-center bg-[var(--dg-body-bg)]'></div> */}
		</>
	);
};

export default memo(GlobalLoading);

import SponsorIdCreateForm from 'components/SponsorId/SponsorIdCreateForm';
import Text from 'core/utils/Text';
import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import DgAlert from 'styles/UI/Alert/DgAlert';

const SponsorIdAlert = () => {
	return (
		<DgAlert
			className='bg-[var(--dg-red-color-2)] border-0 lg-600:flex-row flex-col gap-5'
			message={
				<p className='flex gap-x-3 m-0 h-10 items-center'>
					<span>
						<AiFillCloseCircle size={20} />
					</span>
					<Text tid='please-enter-your-referral-code' className='text-base' />
				</p>
			}
			action={<SponsorIdCreateForm />}
		/>
	);
};

export default SponsorIdAlert;

import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineInfo } from 'react-icons/ai';
import { FC, ReactNode, useEffect, useState } from 'react';
import DgButton from '../Button/DgButton';
import { Helmet } from 'react-helmet';
import DgTab from '../Tab/DgTab';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import DgTooltip from '../Tooltip/DgTooltip';
import useTranslate from 'core/utils/useTranslate';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface IDgCardHeader {
	title?: string;
	tabTitle?: string;
	errorIconColor?: boolean;
	disabled?: boolean;
	hasBorder?: boolean;
	customTitle?: ReactNode;
	subTitle?: string;
	customButton?: ReactNode;
	buttonText?: string;
	tabClassName?: string;
	to?: string;
	buttons?: { title: string; disabled: boolean; to?: string; customButton?: ReactNode; onClick?: () => void }[];
	onClick?: () => void;
	tabOptions?: { tabs: { key: string; label: string | ReactNode; children: ReactNode }[]; activeTab: string; onChange: (val: any) => void };
	back?: string;
}

const DgCardHeader: FC<IDgCardHeader> = ({
	tabTitle = '',
	errorIconColor = false,
	disabled = false,
	hasBorder = true,
	title,
	customTitle,
	subTitle,
	customButton,
	buttonText = 'new',
	to,
	buttons = [],
	onClick,
	tabOptions,
	tabClassName,
	back = '',
}) => {
	const [pageTitle, setPageTitle] = useState('');
	const navigate = useNavigate();
	const { lang } = useSelector((store: RootState) => store.settingStore);
	const { t } = useTranslate();

	//effect
	useEffect(() => {
		setPageTitle(`${tabTitle || title ? t(tabTitle || title || '') + ' - ' + process.env.REACT_APP_TAB_NAME : process.env.REACT_APP_TAB_NAME}`);
	}, [title, tabTitle, t(tabTitle), t(title || '')]);

	const haveTabs = tabOptions && tabOptions?.tabs?.length > 0;

	const header = (
		<>
			<div className={`${!haveTabs ? `${hasBorder ? 'border-b border-b-dg-border-color' : ''}` : ''} ${hasBorder ? 'pb-4' : ''} shrink-0`}>
				<div className='flex flex-col gap-4 xs:flex-row xs:justify-between xs:items-center'>
					<div>
						<div className='flex items-center'>
							{back && (
								<DgTooltip title='back'>
									{lang === 'en' ? (
										<FiArrowLeft onClick={() => navigate(back)} size={25} className='mr-3 text-dg-orange-color cursor-pointer' />
									) : (
										<FiArrowRight onClick={() => navigate(back)} size={25} className='ml-3 text-dg-orange-color cursor-pointer' />
									)}
								</DgTooltip>
							)}
							{title && <h1 className='my-0 text-lg font-bold text-dg-primary-color'>{customTitle || t(title)}</h1>}
						</div>

						{subTitle && (
							<div className='flex items-center my-0 text-xs'>
								<AiOutlineInfo className={`ml-2 ${errorIconColor && 'text-t-error-color'}`} />
								{subTitle}
							</div>
						)}
					</div>

					<>
						{(buttons.length && (
							<div className='flex flex-col gap-2 xs:flex-row'>
								{buttons.map((item, index) =>
									item.to ? (
										<Link key={index} to={item.to || ''}>
											<DgButton disabled={item.disabled} title={item.title} />
										</Link>
									) : item.customButton ? (
										item.customButton
									) : (
										<DgButton disabled={item.disabled} key={index} onClick={item.onClick} title={item.title} />
									)
								)}
							</div>
						)) ||
							(to && (
								<Link to={to}>
									<DgButton disabled={disabled} title={buttonText} />
								</Link>
							)) || <div>{customButton ? customButton : onClick && <DgButton disabled={disabled} onClick={onClick} title={buttonText} />}</div>}
					</>
				</div>
			</div>
		</>
	);

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
			</Helmet>
			<div className={`${!haveTabs && `${hasBorder ? 'mb-4' : 'mb-2'}`} border-t-border-color-base`}>
				{haveTabs && <DgTab header={header} className={tabClassName} {...tabOptions} />}
				{!haveTabs && header}
			</div>
		</>
	);
};

export default DgCardHeader;

import { Input, InputProps, InputRef } from 'antd';
import { forwardRef } from 'react';

export interface IInput extends InputProps {
	className?: string;
}

const DgInput = forwardRef<InputRef, IInput>(({ className, ...props }, ref) => {
	return <Input ref={ref} className={`rounded-md px-3 pt-[10px] ${className}`} {...props} />;
});

DgInput.displayName = 'DgInput';

export default DgInput;

import { FC, ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useWindowSize } from '../../core/hooks/useWindowSize';
import MasterHeader from './MasterHeader';
import { TABLET_SIZE } from '../../core/constants/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMainTheme } from '../../core/utils/theme';
import BottomBar from './BottomBar';
import SupportChat from './crisp/components/SupportChat';
import { routes } from 'core/constants/routes';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface IPublicLayout {
	children: ReactNode;
}
const PublicLayout: FC<IPublicLayout> = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const { width } = useWindowSize();

	const { isAuthenticated } = useSelector((store: RootState) => store.mainInfoStore);
	const { lang, theme } = useSelector((store: RootState) => store.settingStore);

	useEffect(() => {
		if (!isAuthenticated && location.pathname !== routes.REGISTER_SIGNING) {
			navigate(routes.REGISTER_SIGNING);
		}
	}, []);

	return (
		<ThemeProvider theme={getMainTheme(theme, lang)}>
			<div className='min-h-screen flex flex-col '>
				<MasterHeader setIsMenuOpen={setIsMenuOpen} />

				<div className='flex-1 xs:mb-0 xs:my-0 mt-7 mb-16'>{children}</div>
			</div>

			{width < TABLET_SIZE && <BottomBar />}
			<SupportChat />
		</ThemeProvider>
	);
};

export default PublicLayout;

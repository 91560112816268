import useTranslate from 'core/utils/useTranslate';
import { isSelectedItemRoute } from 'core/utils/utils';
import React, { FC, ReactNode } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';

interface IProfileSidebarLink {
	title: string;
	to: string;
	icon: ReactNode;
}

const ProfileSidebarLink: FC<IProfileSidebarLink> = ({ icon, to, title }) => {
	const { t } = useTranslate();
	const { pathname } = useLocation();

	return (
		<Link
			className={`relative py-4 ld:px-6 px-4 flex items-center gap-x-3 ${isSelectedItemRoute(to, pathname) ? 'text-[var(--dg-orange-color)]' : ''}`}
			to={to}
		>
			{icon}
			<span className='flex-1'>{t(title)}</span>

			<RiArrowRightSLine size={20} />
		</Link>
	);
};

export default ProfileSidebarLink;

import CrispProvider from 'components/layouts/crisp/components/CrispProvider';
import GlobalLoading from 'components/layouts/GlobalLoading';
import PrivateLayout from 'components/layouts/PrivateLayout';
import PublicLayout from 'components/layouts/PublicLayout';
import PrivateRoute from 'components/Route/PrivateRoute';
import { routes } from 'core/constants/routes';
import { lazy, memo, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RootState } from 'core/store';
import ProfileLayout from 'components/layouts/ProfileLayout';
import useACL from 'core/services/role/useACL';
import UserDepositWalletTransfer from 'components/UserWallets/components/Transfer/UserDepositWalletTransfer';
import { getUserRoleQuery } from 'core/hooks/react-query/getUserRoleQuery';
import SocketProvider from 'core/providers/socket/SocketProvider';
import { ScaleLoader } from 'react-spinners';
import AdminBannerPageList from './Admin/Banner/List/AdminBannerPageList';

// public
const LoginRegisterPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./LoginRegister/LoginRegisterPage');
});

// private
const HijackPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Hijack/HijackPage');
});
const MainUserDashboard = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Dashboard/MainUserDashboard');
});
const AdminUserPageDetail = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Admin/User/Detail/AdminUserPageDetail');
});
const AdminUsersPageList = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Admin/User/List/AdminUsersPageList');
});
const AdminRolePageList = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Admin/Role/List/AdminRolePageList');
});
const UsersActivityListPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Admin/UsersActivity/List/UsersActivityListPage');
});
const AdminSettingsListPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Admin/Settings/List/AdminSettingsListPage');
});
const ProfilePage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Profile/ProfilePage');
});
const ProfileActivityPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Profile/ProfileActivityPage');
});
const ProfileSecurityPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Profile/ProfileSecurityPage');
});
const ProfileNotificationPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Profile/ProfileNotificationPage');
});
const AdminPackagePageList = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Admin/Package/List/AdminPackagePageList');
});
const UserWalletsPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./UserWallets/UserWalletsPage');
});
const UserTransactionPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./UserTransaction/UserTransactionPage');
});
const OrderSubmitPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./OrderSubmit/OrderSubmitPage');
});
const UserActivePlansPageList = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./UserActivePlans/UserActivePlansPageList');
});
const UserTransferPageList = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./UserTransfer/UserTransferPageList');
});
const ProfileMlmSettingPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Profile/ProfileMlmSettingPage');
});
const BinaryTreePage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./BinaryTree/BinaryTreePage');
});
const AdminOrderPageList = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Admin/Order/List/AdminOrderPageList');
});
const UserGoogleAuthPageList = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./UserGoogleAuth/UserGoogleAuthPageList');
});
const UserPlansHistoryPageList = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./UserPlansHistory/UserPlansHistoryPageList');
});
const UserPlanCommissionsPageList = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./UserPlanCommissions/List/UserPlanCommissionsPageList');
});
const AdminPlanCommissionsPageList = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Admin/PlanCommissions/List/AdminPlanCommissionsPageList');
});
const AdminUserTransactionPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Admin/UserTransaction/AdminUserTransactionPage');
});
const UserDepositPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./UserDeposit/UserDepositPage');
});
const UserWithdrawPage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./UserWithdraw/UserWithdrawPage');
});
const AdminInvoicePage = lazy(async () => {
	// await new Promise((resolve) => setTimeout(resolve, 4000));
	return import('./Admin/Invoice/AdminInvoicePage');
});

const MainRouter = () => {
	// store
	const { isAuthenticated } = useSelector((store: RootState) => store.mainInfoStore);

	// hooks
	const { hasAccessTo, roles } = useACL();

	// query
	const { isFetching: userRolesIsFetching } = getUserRoleQuery();

	const privateRouteLists = [
		{ path: routes.DASHBOARD, element: <MainUserDashboard />, role: true },
		{ path: routes.BINARY_TREE, element: <BinaryTreePage />, role: true },
		{
			path: routes.PROFILE,
			element: (
				<ProfileLayout>
					<ProfilePage />
				</ProfileLayout>
			),
			role: true,
		},
		{
			path: routes.PROFILE_ACTIVITY,
			element: (
				<ProfileLayout>
					<ProfileActivityPage />
				</ProfileLayout>
			),
			role: true,
		},
		{
			path: routes.PROFILE_SECURITY,
			element: (
				<ProfileLayout>
					<ProfileSecurityPage />
				</ProfileLayout>
			),
			role: true,
		},
		{
			path: routes.PROFILE_SECURITY_ACTIVE_2FA,
			element: <UserGoogleAuthPageList />,
			role: true,
		},
		{
			path: routes.PROFILE_MLM,
			element: (
				<ProfileLayout>
					<ProfileMlmSettingPage />
				</ProfileLayout>
			),
			role: true,
		},
		{
			path: routes.PROFILE_NOTIFICATIONS,
			element: (
				<ProfileLayout>
					<ProfileNotificationPage />
				</ProfileLayout>
			),
			role: true,
		},
		{
			path: routes.HIJACK.route,
			element: <HijackPage />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_USER,
			element: <AdminUsersPageList />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_USER_Detail_PAGE.route,
			element: <AdminUserPageDetail />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_USER_Detail.route,
			element: <AdminUserPageDetail />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_ROLE,
			element: <AdminRolePageList />,
			role: hasAccessTo(roles.superAdmin),
		},
		{
			path: routes.ADMIN_USERS_ACTIVITY,
			element: <UsersActivityListPage />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_USERS_HISTORY,
			element: <>user history</>,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_SETTINGS,
			element: <AdminSettingsListPage />,
			role: hasAccessTo(roles.superAdmin),
		},
		{
			path: routes.ADMIN_INVOICE,
			element: <AdminInvoicePage />,
			role: hasAccessTo(roles.superAdmin),
		},
		{
			path: routes.ADMIN_PACKAGE,
			element: <AdminPackagePageList />,
			role: hasAccessTo(roles.package?.admin),
		},
		{
			path: routes.ADMIN_BANNERS,
			element: <AdminBannerPageList />,
			role: hasAccessTo(roles.package?.admin),
		},
		{
			path: routes.ADMIN_ORDER,
			element: <AdminOrderPageList />,
			role: hasAccessTo(roles.order?.admin),
		},
		{
			path: routes.ADMIN_USERS_TRANSACTIONS,
			element: <AdminUserTransactionPage />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_PLANS_COMMISSIONS(),
			element: <AdminPlanCommissionsPageList />,
			role: hasAccessTo(roles.mlm?.admin),
		},
		{ path: routes.WALLETS, element: <UserWalletsPage />, role: true },
		{ path: routes.TRANSACTION, element: <UserTransactionPage />, role: true },
		{
			path: routes.WALLETS_DEPOSIT_TRANSFER,
			element: <UserDepositWalletTransfer />,
			role: true,
		},
		{ path: routes.ORDER_SUBMIT_STEPS.route, element: <OrderSubmitPage />, role: true },
		{ path: routes.ORDERS_LIST, element: <UserActivePlansPageList />, role: true },
		{ path: routes.TRANSFER, element: <UserTransferPageList />, role: true },
		{ path: routes.ORDERS_HISTORY, element: <UserPlansHistoryPageList />, role: true },
		{
			path: routes.PLANS_COMMISSIONS(),
			element: <UserPlanCommissionsPageList />,
			role: true,
		},
		{ path: routes.WALLETS_DEPOSIT, element: <UserDepositPage />, role: true },
		{ path: routes.WALLETS_WITHDRAW, element: <UserWithdrawPage />, role: true },
	];

	const publicRouteLists = [
		{ path: routes.REGISTER_SIGNING, element: <LoginRegisterPage /> },
	];

	if (userRolesIsFetching)
		return (
			<div className='w-full h-screen fixed flex justify-center items-center bg-[var(--dg-body-bg)]'>
				<ScaleLoader height={26} width={3} color='#ffc800' />{' '}
			</div>
		);

	return (
		<CrispProvider>
			<Suspense fallback={<GlobalLoading />}>
				{isAuthenticated ? (
					<PrivateLayout>
						<SocketProvider>
							<Routes>
								{privateRouteLists.map((route) => (
									<Route
										key={route.path}
										path={route.path}
										element={<PrivateRoute role={route.role} />}
									>
										<Route {...route} />
									</Route>
								))}

								<Route path='*' element={<Navigate to={routes.DASHBOARD} replace />} />
							</Routes>
						</SocketProvider>
					</PrivateLayout>
				) : (
					<PublicLayout>
						<Routes>
							{publicRouteLists.map((route) => (
								<Route key={route.path} {...route} />
							))}

							<Route
								path='*'
								element={<Navigate to={routes.REGISTER_SIGNING} replace />}
							/>
						</Routes>
					</PublicLayout>
				)}
			</Suspense>
		</CrispProvider>
	);
};

export default memo(MainRouter);

import { UseQueryResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import api from 'core/services/fetch-api/useApi';
import { setAuthenticated, setIsHijack } from 'core/store/slice/mainInfo/mainInfoSlice';
import Cookie from 'js-cookie';
import { useDispatch } from 'react-redux';
import useQueryHook from '../masterQuery/useQueryHook';

export const useLogoutQuery = (key: string) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	return useQueryHook([endPointUrls.LOGOUT + key], (data: any) => api.get(endPointUrls.LOGOUT, data), {
		onSuccess: (res: any) => {
			if (res.status === 200) {
				queryClient.clear();
				dispatch(setAuthenticated(false));
				dispatch(setIsHijack(false));
				Cookie.remove('jwt');
				Cookie.remove('refresh-jwt');
				toastHandler('success', res.data.message);
			}
		},
		enabled: false,
	}) as UseQueryResult;
};

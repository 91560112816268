import { Link, useLocation } from 'react-router-dom';
import { CaretDownFilled } from '@ant-design/icons';
import { useEffect, FC, useState, ReactNode, memo } from 'react';
import { NavItemWrapper, NavText } from 'styles/layout-styles/SideBarStyles';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import useACL from 'core/services/role/useACL';

interface subMenu {
	name: string;
	to: string;
	role: boolean;
	navKey: string;
}

interface IDgSidebarMenu {
	name: string;
	navKey?: string;
	openTab?: boolean;
	isOpen?: boolean;
	to?: string;
	icon?: ReactNode;
	role?: boolean;
	subMenu?: subMenu[];
}

const DgSidebarMenu: FC<IDgSidebarMenu> = ({ name, icon, to, subMenu, role, navKey, openTab }) => {
	//state
	const [isOpen, setIsOpen] = useState<boolean>(false);

	//hooks
	const { pathname } = useLocation();
	const { hasAccessTo } = useACL();
	const { t } = useTranslate();

	//effects
	useEffect(() => {
		if (!subMenu) return;
		setIsOpen(isAnySubMenusSelected());
	}, [pathname]);

	//functions
	const getLinkLength = (link?: string) => {
		if (!link) return 0;
		return link.split('/').length - 1;
	};

	const getImportantPartOfPathname = (customLink?: string) => {
		if (!to && !customLink && navKey) return;
		const result = pathname.split('/');
		result.shift();
		return result.slice(0, getLinkLength(navKey || customLink)).join('');
	};

	const isAnySubMenusSelected: () => boolean = () =>
		!!subMenu?.some((item) => {
			return item.to.replaceAll('/', '') === getImportantPartOfPathname(item.to);
		});

	const isSelected = (customLink?: string) => {
		if (customLink) return customLink.replaceAll('/', '') === getImportantPartOfPathname(customLink);
		if (!navKey) return false;
		return navKey.replaceAll('/', '') === getImportantPartOfPathname();
	};

	const subMenuItems = () => {
		return subMenu?.map((item, index) => {
			if (!hasAccessTo(item.role)) return <></>;

			return (
				<div className='px-2 my-3 transition-all duration-300 ease-out group' key={index} onClick={(e) => e.stopPropagation()}>
					<Link to={item.to}>
						<div
							className={`flex items-center px-3 py-1.5 w-3/4 mx-auto transition-all ease-out duration-300 relative rounded-md ${
								isSelected(item.to) ? 'text-[var(--dg-orange-color)]' : ''
							}`}
						>
							<div
								className={`w-2 ${
									isSelected(item.to)
										? 'bg-[var(--dg-primary-color-hover)] transition-all after:absolute after:w-2 after:bg-[var(--dg-orange-color)] after:h-full after:top-0 after:left-0'
										: ''
								}`}
							></div>
							{t(item.name)}
						</div>
					</Link>
				</div>
			);
		});
	};

	const subMenuIsEmpty = (items: subMenu[]) => {
		if (!items || items?.length === 0) return true;
		return items.every((item: subMenu) => !hasAccessTo(item.role));
	};

	//conditions
	if (!subMenu && !hasAccessTo(role)) return <></>;
	if (subMenu && subMenuIsEmpty(subMenu)) return <></>;

	if (to) {
		return (
			<>
				{openTab ? (
					<a
						href={to}
						target='_blank'
						rel='noreferrer'
						className={`${
							isSelected()
								? 'bg-[var(--dg-primary-color-hover)] text-[var(--dg-orange-color)] after:absolute after:w-2 after:bg-[var(--dg-orange-color)] after:h-full after:top-0 after:left-0'
								: ''
						} w-full my-1 py-3 px-2 cursor-pointer transition-all duration-[0.3s] relative text-sm hover:bg-[var(--dg-primary-color-hover)]`}
					>
						<NavItemWrapper>
							{icon}
							<NavText>
								<Text tid={name} />
							</NavText>
						</NavItemWrapper>
					</a>
				) : (
					<Link
						to={to}
						className={`${
							isSelected()
								? 'bg-[var(--dg-primary-color-hover)] text-[var(--dg-orange-color)] after:absolute after:w-2 after:bg-[var(--dg-orange-color)] after:h-full after:top-0 after:left-0'
								: ''
						} w-full my-1 py-3 px-2 cursor-pointer transition-all duration-[0.3s] relative text-sm hover:bg-[var(--dg-primary-color-hover)]`}
					>
						<NavItemWrapper>
							{icon}
							<NavText>
								<Text tid={name} />
							</NavText>
						</NavItemWrapper>
					</Link>
				)}
			</>
		);
	}

	if (subMenu)
		return (
			<div
				className={`w-full my-1 py-3 px-2 cursor-pointer transition-all duration-[0.3s] relative text-sm hover:bg-[var(--dg-primary-color-hover)] overflow-hidden ${
					isOpen ? 'bg-[var(--dg-primary-color-hover)]' : ''
				} `}
				style={{ maxHeight: isOpen ? `${subMenu.length * 47 + 40}px` : '44px' }}
				onClick={() => setIsOpen(!isOpen)}
			>
				<div className={`flex w-4/5 m-auto items-center mb-2 transition-all ease-out duration-300  rounded-md group- ${isOpen && ' '} `}>
					<span style={{ color: 'white !important' }}> {icon}</span>
					{<span className='ml-3 '>{t(name)}</span>}
					{subMenu && <CaretDownFilled className='flex-1 ltr:text-right text-left transition-all' style={{ transform: isOpen ? 'scaleY(-1)' : '' }} />}
				</div>

				{subMenuItems()}
			</div>
		);

	return <></>;
};

export default memo(DgSidebarMenu);

import { createSlice } from '@reduxjs/toolkit';
import { ISubmitOrderPayload } from 'core/types/models/submitOrderType';

const initialState: { currentSubmitOrder: ISubmitOrderPayload } = { currentSubmitOrder: { category_id: 1, price: 100 } };

export const submitOrderSlice = createSlice({
	name: 'submitOrder',
	initialState: initialState,
	reducers: {
		setSubmitOrderPayload: (state, { payload }: { payload: ISubmitOrderPayload }) => {
			state.currentSubmitOrder = payload;
		},
	},
});

export const { setSubmitOrderPayload } = submitOrderSlice.actions;
export default submitOrderSlice.reducer;

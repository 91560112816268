import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import DgSpinner from '../Spinner/DgSpinner';

interface IDgCard {
	children: ReactNode;
	isMarginBottom?: boolean;
	isPadding?: boolean;
	className?: string;
	loading?: boolean;
	hasBackground?: boolean;
}

const DgCard: FC<IDgCard> = ({ isMarginBottom = true, isPadding = false, children, className, loading, hasBackground = true, ...props }) => {
	return (
		<div
			className={twMerge(
				`relative rounded-lg w-full flex-grow-0 p-2 sm:p-5 sm:py-7 pt-3 sm:pt-6 mx-auto ${isMarginBottom ? 'mb-8' : 'mb-0'} ltr:text-left rtl:text-right ${
					hasBackground ? 'bg-dg-secondary-color shadow-lg' : 'bg-transparent shadow-none'
				} ${isPadding ? 'sm:p-0' : ''} ${className}`
			)}
			{...props}
		>
			{loading ? <DgSpinner /> : children}
		</div>
	);
};

export default DgCard;

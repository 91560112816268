import { RootState } from 'core/store';
import { rolesName, rolesType } from 'core/types/roleType';
import { stringToBoolean } from 'core/utils/utils';
import { useSelector } from 'react-redux';

const useACL = () => {
	// store
	const { userRoles } = useSelector((store: RootState) => store.userInfoStore);

	const userRolesInfo = userRoles?.data?.roles?.map((item) => item?.name?.toLowerCase()?.trim()) || [];

	// const userRoles = 'person-list,person-create,Super-Admin,user-admin,order-admin,package-admin,payment-admin,wallet-admin,giftCode-admin,mlm-admin,client';

	// let rolesList = userRoles ? userRoles.split(',') : [];
	// rolesList = rolesList.map((item) => item.toLocaleLowerCase());

	let roles: rolesType = {};

	userRolesInfo.map((item) => {
		if (item.includes('super-admin')) return (roles = { superAdmin: true });
		if (!item.includes('-')) return;
		const role = item.split('-').map((name) => name.charAt(0).toLowerCase() + name.slice(1));
		roles = { ...roles, [role[0]]: { ...roles[role[0] as rolesName], [role[1]]: true } };
	});

	const hasAccessTo = (passedRole: boolean | undefined, test?: boolean, restrict?: boolean) => {
		if (stringToBoolean(process.env.REACT_APP_LOGIN_LOCAL)) return true;
		if (test) return false;
		if (userRolesInfo.length === 0) return false;
		if (!restrict && userRolesInfo.includes('super-admin')) return true;
		return !!passedRole;
	};

	return { roles, hasAccessTo };
};

export default useACL;

import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import { useDispatch, useSelector } from 'react-redux';
import useQueryHook from '../masterQuery/useQueryHook';
import { setUserRoles } from 'core/store/slice/userInfo/userInfoSlice';
import { IUserRoleList } from 'core/types/models/role';
import { RootState } from 'core/store';

export const getUserRoleQuery = () => {
	const dispatch = useDispatch();
	const { isAuthenticated } = useSelector((store: RootState) => store.mainInfoStore);

	return useQueryHook([endPointUrls.PROFILE_ROLES], (data: any) => api.get(endPointUrls.PROFILE_ROLES, data), {
		select: (res: any) => res.data,
		staleTime: 30 * 60 * 1000,
		cacheTime: 30 * 60 * 1000,
		onSuccess: (res: any) => {
			console.log('res', res);
			dispatch(setUserRoles(res));
		},
		enabled: isAuthenticated,
	}) as UseQueryResult<IUserRoleList, Error>;
};

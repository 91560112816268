/**
 * load local storage persisted data
 * @param {string} key
 * @return {null|any}
 */
export const loadPersistedData = (key) => {
	try {
		const serializedData = localStorage.getItem(key);
		if (serializedData === null) {
			return null;
		}
		return key === 'theme' || key === 'lang' ? serializedData : JSON.parse(serializedData);
	} catch (err) {
		return null;
	}
};

/**
 * persist data in local storage
 * @param {string} key
 * @param {any} state
 * @return {void}
 */
export const persistData = (key, state) => {
	try {
		const serializedDate = key === 'theme' || key === 'lang' ? state : JSON.stringify(state);
		localStorage.setItem(key, serializedDate);
	} catch (err) {
		console.log(err);
	}
};

import Index from './pages';
import './assets/css/pagination.css';
import { SkeletonTheme } from 'react-loading-skeleton';
import IndexProviders from 'core/providers/IndexProviders';
import { FC } from 'react';

const App: FC = () => {
	return (
		<IndexProviders>
			<SkeletonTheme baseColor='var(--dg-skeleton-bg)' highlightColor='var(--dg-primary-color-hover)'>
				<Index />
			</SkeletonTheme>
		</IndexProviders>
	);
};

export default App;

import { routes } from 'core/constants/routes';
import { mainFormatNumber } from 'core/utils/utils';
import useUserWallet from 'pages/UserWallets/hooks/useUserWallet';
import React from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import DgImage from 'styles/UI/Image/DgImage';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import UserDepositWalletTransferForm from './UserDepositWalletTransferForm';
import { useNavigate } from 'react-router-dom';
import Text from 'core/utils/Text';
import DgPrice from 'styles/UI/Price/DgPrice';

const UserDepositWalletTransfer = () => {
	const { deposit_Wallet, userWalletIsFetching } = useUserWallet();
	const navigate = useNavigate();

	return (
		<>
			<DgSpinnerFull isLoading={userWalletIsFetching}>
				<div className='grid lg-1300:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 mb-10'>
					<div className='bg-[var(--dg-card-bg-2)] h-32 rounded-lg flex justify-between items-center px-7'>
						<span className='ltr text-xl text-dg-white-color flex flex-col space-y-2'>
							<Text tid='my-wallet' className='text-[var(--dg-orange-color)]' />
							<DgPrice price={deposit_Wallet?.balance || 0} />
						</span>

						<div>
							<DgImage src={require('../../../../assets/images/usd.png')} width={75} />
						</div>
					</div>
				</div>
			</DgSpinnerFull>

			<DgCard>
				<DgCardHeader
					back={routes.WALLETS}
					title='inter-user-transfer'
					buttons={[
						{ title: 'set-transaction-password', onClick: () => navigate(routes.PROFILE_SECURITY), disabled: false },
						{ title: 'history', onClick: () => navigate(routes.TRANSFER), disabled: false },
					]}
					tabTitle='inter-user-transfer'
				/>
				<div className='flex flex-col md:flex-row items-center gap-10'>
					<div>
						<DgImage src={require('../../../../assets/images/transfer_wallet.png')} className='md:max-w-xs max-w-[200px] w-full' />
					</div>

					<div className='flex-1 flex items-center justify-center w-full'>
						<UserDepositWalletTransferForm />
					</div>
				</div>
			</DgCard>
		</>
	);
};

export default UserDepositWalletTransfer;

import toastHandler from 'core/helpers/toast/toast';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useDispatch } from 'react-redux';

const useApiCatcher = () => {
	const dispatch = useDispatch();

	return (errorResponse: any, isSHowMessage = true) => {
		let errorMessages: any[] = [];
		if (!isSHowMessage) return;

		if (
			errorResponse?.data?.length === 0 ||
			errorResponse?.data === null ||
			!Array.isArray(errorResponse?.data)
		) {
			// if(err)
			for (const key in errorResponse?.data) {
				if (Object.hasOwnProperty.call(errorResponse?.data, key)) {
					const element = errorResponse?.data[key];
					toastHandler('error', `${key} : ${element?.[0]}` || element);
					errorMessages = [...errorMessages, { name: key, errors: [element?.[0]] }];
				}
			}

			toastHandler('error', errorResponse?.errors?.subject || errorResponse?.message);
		} else {
			// console.log('obje555555555ct');
			for (const key in errorResponse?.data) {
				if (Object.hasOwnProperty.call(errorResponse?.data, key)) {
					const element = errorResponse?.data[key];
					toastHandler('error', `${key} : ${element?.[0]}` || element);
					errorMessages = [...errorMessages, { name: key, errors: [element?.[0]] }];
				}
			}
		}

		dispatch(setApiCatcherFormErrors(errorMessages));
	};
};

export default useApiCatcher;

import { combineReducers } from 'redux';

import mainInfoStore from './slice/mainInfo/mainInfoSlice';
import settingStore from './slice/setting/settingSlice';
import userInfoStore from './slice/userInfo/userInfoSlice';
import modalStore from './slice/modal/modalSlice';
import countryStore from './slice/country/countrySlice';
import submitOrderStore from './slice/submitOrder/submitOrderSlice';
import walletStore from './slice/wallet/walletReducer';
import socketStore from './slice/socket/socketSlice';
import notificationStore from './slice/notification/notificationSlice';
import depositStore from './slice/deposit/depositSlice';
import withdrawStore from './slice/withdraw/withdrawSlice';

const rootReducer = combineReducers({
	mainInfoStore,
	settingStore,
	userInfoStore,
	modalStore,
	countryStore,
	submitOrderStore,
	walletStore,
	socketStore,
	notificationStore,
	depositStore,
	withdrawStore,
});

export default rootReducer;

import { FC } from 'react';
import useTranslate from './useTranslate';

interface IText {
	tid: string;
	className?: string;
}

const Text: FC<IText> = ({ tid, className = '', ...rest }) => {
	const { t } = useTranslate();
	const result = t(tid);
	return (
		<span className={`inline-block ${className}`} {...rest}>
			{result ? result : tid}
		</span>
	);
};

export default Text;

import { AutoComplete, Input, InputProps, InputRef } from 'antd';
import { forwardRef } from 'react';

export interface IInput extends InputProps {
	className?: string;
}

const DgInputPassword = forwardRef<InputRef, IInput>(({ className, ...props }, ref) => {
	return (
		<AutoComplete>
			<Input ref={ref} className={`rounded-md px-3 pt-[10px] dg-input-password ${className}`} autoComplete='off' {...props} />
		</AutoComplete>
	);
});

DgInputPassword.displayName = 'DgInputPassword';

export default DgInputPassword;

import {
	useMutation,
	QueryKey,
	UseMutationOptions,
	MutationFunction,
} from '@tanstack/react-query';
import useApiCatcher from '../useApiCatcher';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { setAuthenticated, setIsHijack } from 'core/store/slice/mainInfo/mainInfoSlice';
import { routes } from 'core/constants/routes';
import { stringToBoolean } from 'core/utils/utils';

const useMutationHook = (
	queryKey: QueryKey,
	queryFn: MutationFunction,
	queryOptions: UseMutationOptions,
	showError = true
) => {
	//hooks
	const apiCatcher = useApiCatcher();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return useMutation(queryKey, queryFn, {
		...queryOptions,
		onError: (res: any) => {
			if (
				res?.response?.status === 401 &&
				!stringToBoolean(process.env.REACT_APP_LOGIN_LOCAL)
			) {
				Cookies.remove('jwt');
				Cookies.remove('refresh-jwt');
				dispatch(setIsHijack(false));
				dispatch(setAuthenticated(false));
				navigate(routes.REGISTER_SIGNING);
				document.title = process.env.REACT_APP_TAB_NAME || '';
			}

			showError && apiCatcher(res.response?.data);
		},
	});
};

export default useMutationHook;

import { FC, forwardRef, useState, useEffect } from 'react';
import { CgMenuLeftAlt, CgProfile } from 'react-icons/cg';
import { useWindowSize } from '../../core/hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import { Body, DropBody, DropItem, Flex, MLink } from '../../styles/layout-styles/HeaderStyles';
import { CLOSE_SIDEBAR_SIZE } from '../../core/constants/common';
import Text from '../../core/utils/Text';
import { MdNotificationsActive } from 'react-icons/md';
import { routes } from 'core/constants/routes';
import DgDropDown from 'styles/UI/DropDown/DgDropDown';
import logo from '../../assets/images/logo/logo.png';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { Form, MenuProps } from 'antd';
import ProfileWidget from 'components/ProfileWidget/ProfileWidget';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import DgBadge from 'styles/UI/Badge/DgBadge';
import NotificationWidget from 'components/NotificationWidget/NotificationWidget';
import { getNotificationUnreadCount } from 'core/hooks/react-query/getNotificationUnreadCount';
import { useUserNotificationsQuery } from 'components/Profile/hooks/react-query/useUserNotificationsQuery';
import { FiSend } from 'react-icons/fi';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import DgModal from 'styles/UI/Modal/DgModal';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgTextAreaLabel from 'styles/UI/TextArea/DgTextAreaLabel';
import DgButton from 'styles/UI/Button/DgButton';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import useTranslate from 'core/utils/useTranslate';
import { sendNotificationMessage } from 'core/hooks/react-query/sendNotificationMessage';
import useModal from 'core/hooks/useModal';
import useACL from 'core/services/role/useACL';

interface IMasterHeader {
	setIsMenuOpen?: any;
}

const MasterHeader: FC<IMasterHeader> = ({ setIsMenuOpen }) => {
	// hooks
	const { pathname } = useMasterQueryUrlParam();
	const { width } = useWindowSize();
	const navigate = useNavigate();
	const { t } = useTranslate();
	const { modal, hideModal, showModal } = useModal();
	const [form] = Form.useForm();
	const { roles, hasAccessTo } = useACL();

	// store
	const { isAuthenticated } = useSelector((store: RootState) => store.mainInfoStore);
	const { notificationCount } = useSelector((store: RootState) => store.notificationStore);

	// state
	const [profileDropdownStatus, setProfileDropdownStatus] = useState<any>({});

	// query
	getNotificationUnreadCount();
	const { mutate: sendNotification, isLoading: isLoadingSendMessage } = sendNotificationMessage();
	useUserNotificationsQuery('per_page=100');

	useEffect(() => {
		profileDropdownStatus?.hideDropdown?.();
	}, [pathname]);

	const goToSignin = () => {
		navigate(routes.REGISTER_SIGNING);
	};

	const respMenuClicked = () => {
		setIsMenuOpen((state: boolean) => !state);
	};

	const getProfileDropdownStatus = (openDropdown: any, hideDropdown: any, showDropdown: any) => {
		setProfileDropdownStatus({ openDropdown, hideDropdown, showDropdown });
	};

	const profileDropdown: MenuProps['items'] = [
		{
			label: <ProfileWidget />,
			key: '0',
		},
	];

	const notificationDropdown: MenuProps['items'] = [
		{
			label: <NotificationWidget />,
			key: '0',
		},
	];

	const onSendNotificationHandler = (values: { title: string; body: string }) => {
		sendNotification(
			{ ...values },
			{
				onSuccess: () => {
					hideModal();
					form.resetFields();
				},
			}
		);
	};

	return (
		<>
			{modal && (
				<DgModal title='send-message' open={modal} onCancel={hideModal}>
					<DgForm onFinish={onSendNotificationHandler} form={form}>
						<DgFormWrapper singleColumn>
							<DgFormItem name='title' rules={[{ required: true, message: t('title-is-required') }]}>
								<DgInputLabel label='title' required />
							</DgFormItem>

							<DgFormItem name='body' rules={[{ required: true, message: t('message-is-required') }]}>
								<DgTextAreaLabel label='message' required />
							</DgFormItem>

							<DgFormItem button>
								<DgButton title='send' className='w-full' isLoading={isLoadingSendMessage} />
							</DgFormItem>
						</DgFormWrapper>
					</DgForm>
				</DgModal>
			)}
			<div className='py-5 sticky top-0 z-[999999999] shadow-dg-header-shadow shadow-[0px_2px_12px] bg-header-bg'>
				<Body className='w-full px-3 xs:px-12 text-white gap-2'>
					<div className='flex flex-1 h-[50px] items-center gap-5 relative top-[1px]'>
						<img src={logo} width={'130px'} className='cursor-pointer' onClick={() => navigate(routes.DASHBOARD)} />
					</div>
					<div className='flex items-center gap-7'>
						{isAuthenticated && hasAccessTo(roles.superAdmin) && (
							<DgTooltip title='send-message'>
								<FiSend className='cursor-pointer hover:text-dg-orange-color' onClick={showModal} size={width < CLOSE_SIDEBAR_SIZE ? 20 : 25} />
							</DgTooltip>
						)}

						{isAuthenticated && (
							<Flex style={{ position: 'relative' }}>
								<DgDropDown menu={{ items: notificationDropdown }} placement='bottomLeft'>
									<DgBadge count={notificationCount} offset={[5, -5]}>
										<MdNotificationsActive className='cursor-pointer hover:text-dg-orange-color' size={width < CLOSE_SIDEBAR_SIZE ? 20 : 25} />
									</DgBadge>
								</DgDropDown>
							</Flex>
						)}

						{isAuthenticated ? (
							<DgDropDown getDropdownStatus={getProfileDropdownStatus} menu={{ items: profileDropdown }}>
								<CgProfile size={27} className='cursor-pointer' />
							</DgDropDown>
						) : (
							<div className='flex gap-4'>
								<MLink className='text-dg-white-color hover:bg-dg-primary-color-light-hover-2 rounded-xl py-3 px-5 flex items-center gap-1 border' onClick={() => goToSignin()}>
									<Text tid='register' className='text-sm font-bold text-dg-orange-color' />

									<b>/</b>
									<Text tid='login' className='text-sm font-bold' />
								</MLink>
							</div>
						)}

						{width < CLOSE_SIDEBAR_SIZE && isAuthenticated && (
							<div className='cursor-pointer' onClick={respMenuClicked}>
								<CgMenuLeftAlt size={28} color='#c3c5b7' />
							</div>
						)}
					</div>
				</Body>
			</div>
		</>
	);
};

interface IDropdown {
	options: any;
	active: any;
	onOptionClicked: (option: any) => void;
}

const Dropdown = forwardRef<HTMLDivElement, IDropdown>(({ options, active, onOptionClicked }, ref) => {
	return (
		<DropBody className='bg-dg-dropdown-bg' ref={ref}>
			{options.map((option: any) => (
				<DropItem key={option} onClick={() => onOptionClicked(option)} active={active === option}>
					<Flex style={{ padding: '0 20px' }}>{option}</Flex>
				</DropItem>
			))}
		</DropBody>
	);
});

Dropdown.displayName = 'Dropdown';

export default MasterHeader;

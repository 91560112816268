import { Form, RadioChangeEvent } from 'antd';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { userContactUpdateInfoPayload, userPersonalUpdateInfoPayload } from 'core/types/models/userInfo';
import toastHandler from 'core/helpers/toast/toast';
import useTranslate from 'core/utils/useTranslate';
import { setCountrySelectedData } from 'core/store/slice/country/countrySlice';
import { convertDatePicker } from 'core/utils/utils';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import { useUpdateUserProfileAvatar } from './react-query/useUpdateUserProfileAvatar';
import { setHiddenModal, setShowModal } from 'core/store/slice/modal/modalSlice';
import { useUpdateUserPersonalProfile } from './react-query/useUpdateUserPersonalProfile';
import { useUpdateUserContactProfile } from './react-query/useUpdateUserContactProfile';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';

const useProfile = () => {
	// hooks
	const [userUpdatePersonalForm] = Form.useForm();
	const [userUpdateContactForm] = Form.useForm();
	const dispatch = useDispatch();
	const { t } = useTranslate();
	const avatarRef = useRef<HTMLInputElement>(null);

	// query
	const { data: userInfo, isLoading: isLoadingGetUser } = getCurrentUserInfoQuery();
	const { mutate: personalUpdate, isLoading: isLoadingUpdatePersonalUser } = useUpdateUserPersonalProfile(() => hideModal());
	const { mutate: contactUpdate, isLoading: isLoadingUpdateContactUser } = useUpdateUserContactProfile(() => hideModal());
	const { mutate: updateAvatar, isLoading: isLoadingUpdateAvatar } = useUpdateUserProfileAvatar(() => dropdownStatus?.hideDropdown());

	// state
	const [genderType, setGenderType] = useState(userInfo?.data.gender);
	const [isPersonalModal, setIsPersonalModal] = useState(true);
	const [dropdownStatus, setDropdownStatus] = useState<any>({});
	const [modalDetail, setModalDetail] = useState<string>('');

	// store
	const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
	const { countrySelectedData } = useSelector((store: RootState) => store.countryStore);
	const { modal } = useSelector((store: RootState) => store.modalStore);

	useEffect(() => {
		userUpdatePersonalForm.setFieldsValue({ gender: genderType });
	}, [genderType]);

	useEffect(() => {
		userUpdatePersonalForm.setFieldsValue({ ...userInfo?.data, gender: userInfo?.data.gender });
		userUpdateContactForm.setFieldsValue({ ...userInfo?.data });
		dispatch(setCountrySelectedData({ id: userInfo?.data.country_id }));
	}, [userInfo?.data]);

	useEffect(() => {
		userUpdatePersonalForm.setFields(apiCatcherFormErrors);
		userUpdateContactForm.setFields(apiCatcherFormErrors);
	}, [apiCatcherFormErrors]);

	const genderOnChangeHandler = (e: RadioChangeEvent): void => {
		setGenderType(e.target.value);
	};

	// personal detail update
	const onFinishUpdatePersonalUser = (values: userPersonalUpdateInfoPayload) => {
		personalUpdate({
			first_name: values.first_name || userInfo?.data?.first_name,
			last_name: values.last_name || userInfo?.data?.last_name,
			gender: values.gender || userInfo?.data?.gender,
			birthday: convertDatePicker(values.birthday) || userInfo?.data?.birthday,
		});
	};

	// contact detail update
	const onFinishUpdateContactUser = (values: userContactUpdateInfoPayload) => {
		contactUpdate({
			address_line1: values.address_line1 || userInfo?.data?.address_line1,
			country_id: values.country_id || userInfo?.data?.country_id,
			zip_code: values.zip_code || userInfo?.data?.zip_code,
			mobile_number: values.mobile_number || userInfo?.data?.mobile_number,
		});
	};

	// upload avatar
	const onUpdateAvatarClicked = (e: FormEvent<HTMLInputElement>) => {
		if (e.currentTarget?.files?.[0]?.size && e.currentTarget?.files?.[0]?.size > 350000) {
			toastHandler('error', t('image-size-error'));
		} else {
			const files = new FormData();
			if (e.currentTarget?.files?.[0]) {
				files.append('avatar', e.currentTarget?.files?.[0]);
				files.append('user_id', userInfo?.data.id + '');
				updateAvatar(files as any);
			}
		}
	};

	const showModalPersonalModal = (detail: string) => {
		setModalDetail(detail);
		dispatch(setShowModal());
		setIsPersonalModal(true);
	};

	const showModalContactModal = (detail: string) => {
		setModalDetail(detail);
		dispatch(setShowModal());
		setIsPersonalModal(false);
	};

	const hideModal = () => {
		dispatch(setHiddenModal());
		dispatch(setApiCatcherFormErrors([]));
	};

	const getDropdownStatus = (openDropdown: any, hideDropdown: any, showDropdown: any) => {
		setDropdownStatus({ openDropdown, hideDropdown, showDropdown });
	};

	return {
		onUpdateAvatarClicked,
		userUpdatePersonalForm,
		isLoadingUpdateContactUser,
		onFinishUpdateContactUser,
		userUpdateContactForm,
		userInfo,
		avatarRef,
		modal,
		isPersonalModal,
		showModalContactModal,
		showModalPersonalModal,
		hideModal,
		isLoadingUpdateAvatar,
		genderOnChangeHandler,
		onFinishUpdatePersonalUser,
		countrySelectedData,
		getDropdownStatus,
		isLoadingUpdatePersonalUser,
		isLoadingGetUser,
		modalDetail,
	};
};

export default useProfile;

import useCopyToClipboard from 'core/hooks/useCopyToClipboard';
import Text from 'core/utils/Text';
import { FaRegCopy } from 'react-icons/fa';
import DgTooltip from '../Tooltip/DgTooltip';

const DgReferralCode = ({ member_id, hasTitle = true, hasHorizontal = true }: { member_id: string | number; hasTitle?: boolean; hasHorizontal?: boolean }) => {
	const { copyHandler } = useCopyToClipboard();

	return (
		<DgTooltip title='copy'>
			{hasHorizontal ? (
				<span
					onClick={() => copyHandler(member_id + '')}
					className='flex px-4 py-2.5 rounded-lg bg-[var(--dg-card-bg-2)] justify-center md:gap-0 xs:gap-5 gap-2 md:justify-between items-center cursor-pointer'
				>
					{hasTitle && <Text tid='referral-code' />}
					<span className='text-[var(--dg-orange-color)]'>{member_id}</span>
					<FaRegCopy />
				</span>
			) : (
				<span
					onClick={() => copyHandler(member_id + '')}
					className='flex px-4 py-2.5 rounded-lg bg-[var(--dg-card-bg-2)] justify-between md:gap-1 max-w-[300px] w-full mx-auto xs:gap-5 gap-2 md:justify-between items-center cursor-pointer'
				>
					<div className='flex-col flex items-start'>
						{hasTitle && <Text tid='referral-code' />}
						<span className='text-[var(--dg-orange-color)]'>{member_id}</span>
					</div>
					<FaRegCopy />
				</span>
			)}
		</DgTooltip>
	);
};

export default DgReferralCode;

import React from 'react';
import DgButton from 'styles/UI/Button/DgButton';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import useSponsorId from './hooks/useSponsorId';

const SponsorIdCreateForm = () => {
	const { onFinishSponsorId, sponsorIdForm, sponsorIdIsLoading } = useSponsorId();

	return (
		<DgForm form={sponsorIdForm} onFinish={onFinishSponsorId} className='m-0'>
			<div className='flex'>
				<DgFormItem noMargin name='sponsor_id'>
					<DgInputLabel label='referral-code' noRightRound />
				</DgFormItem>

				<DgButton isLoading={sponsorIdIsLoading} responsive={false} noLeftRound title='submit' height='h-[49px]' />
			</div>
		</DgForm>
	);
};

export default SponsorIdCreateForm;

import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { IWalletList } from 'core/types/models/walletsType';
import { useDispatch } from 'react-redux';
import { setUserWallet } from 'core/store/slice/wallet/walletReducer';

export const getUserWalletsQuery = () => {
	const dispatch = useDispatch();

	return useQueryHook([endPointUrls.WALLETS], () => api.get(endPointUrls.WALLETS), {
		select: (res: any) => res.data,
		onSuccess: (res: any) => {
			dispatch(setUserWallet(res));
		},
	}) as UseQueryResult<IWalletList, Error>;
};

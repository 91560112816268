import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const useCreateCrisp = () => {
	const { lang } = useSelector((store: RootState) => store.settingStore);

	useEffect(() => {
		const d = document;
		const s = document.createElement('script');
		window.$crisp = [];
		window.CRISP_WEBSITE_ID = '646d1962-7489-463b-8e26-02aeffd38416';
		window.CRISP_RUNTIME_CONFIG = {
			locale: lang,
		};

		(function () {
			s.src = 'https://client.crisp.chat/l.js';
			s.async = true;
			createCrisp();
			d.getElementsByTagName('head')[0].appendChild(s);
		})();
	}, []);
};

export const createCrisp = () => {
	window.$crisp.push(['do', 'chat:hide']);
	// window.$crisp.push(['config', 'position:reverse', [true]]);
	window.$crisp.push(['config', 'color:theme', ['amber']]);
	window.$crisp.push(['on', 'chat:closed', () => window.$crisp.push(['do', 'chat:hide'])]);
};

export const resetCrisp = () => {
	//TODO: delete if not needed - useCase in useAuthQuery - logout
};

export default useCreateCrisp;

import React, { FC } from 'react';
import SponsorIdAlert from './components/SponsorIdAlert';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import Google2faAlert from './components/Google2faAlert';

const HeaderAlert: FC = () => {
	const { data: currentUserInfo, isFetching } = getCurrentUserInfoQuery();

	return (
		<>
			{!isFetching && !currentUserInfo?.data.sponsor_id && <SponsorIdAlert />}
			{!isFetching && !currentUserInfo?.data.google2fa_enable && <Google2faAlert />}
		</>
	);
};

export default HeaderAlert;
